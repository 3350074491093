// import axios from 'axios';

// export const httpClient = axios.create({
//   baseURL: `${process.env.PUBLIC_URL}`, //YOUR_API_URL HERE
//   headers: {
//     'Content-Type': 'application/json',
//   },
// });


import axios from "./Axios";
export const httpClient = axios;