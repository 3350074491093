import { Card, Form, Input, Select, Table, Row, Col, Flex, Button } from "antd";
import { PRODUCT_TYPES, LICENSE_TYPES } from "constants/License";
import React, { useEffect, useState } from "react";
import { convertDateFormat } from "util/common";
import licenseApis from "util/service/licenseApis";

const List = () => {

  const [data, setData] = useState([]);
  const [tableScrollY, setTableScrollY] = useState(0);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [ordering, setOrdering] = useState([]);

  const productTypeArr = Object.entries(PRODUCT_TYPES).map(([key, value]) => ({type: key, value}) )
  const options = LICENSE_TYPES.map((item) => ({label: item, value: item}) )
  const [focus, setFocus] = useState(false);

  const [form] = Form.useForm();


  useEffect(() => {
    setFocus(true);
  }, [])

  useEffect(() => {
    if (focus) {

      getData();

      handleResize(); // 컴포넌트가 마운트될 때 초기 높이 설정
      window.addEventListener("resize", handleResize); // 창 크기 변경 이벤트 리스너 추가
      return () => window.removeEventListener("resize", handleResize); // 컴포넌트 언마운트 시 이벤트 리스너 제거
    }

  }, [focus]);

  useEffect(() => {
    if (focus) {
      getData();

    }
  }, [current, pageSize, ordering])

   const getData = () => {
    const values = form.getFieldsValue();

    const req = {...values};

    if (ordering.length) {
      req.ordering = ordering;
    }

    licenseApis.searchLicenseList(req, pageSize, current).then((resp) => {
      setData(resp.results)
      setTotal(resp.count);
    })
  }


  const handleResize = () => {
    const tableCardOffset = 420; // 상단 패딩, Card 및 기타 요소의 높이 조정 값 (필요에 따라 조정)
    const newTableScrollY = window.innerHeight - tableCardOffset;
    setTableScrollY(newTableScrollY > 0 ? newTableScrollY : 0); // 높이가 음수일 수 없으므로 0으로 설정
  };

  const columns = [
    {
      title: "발급일",
      dataIndex: "at",
      render: (data) => convertDateFormat(data, "withTime"),
      sorter: true
    },
    {
      title: "Hostname",
      dataIndex: "hostname",
      sorter: true

    },
    {
      title: "Machine id",
      dataIndex: "machine_id",
      sorter: true

    },
    {
      title: "Product type",
      dataIndex: "product_type",
      sorter: true,
      render: (data) => {
        const find = productTypeArr.find((item) => item.value === data)
        if (find)
          return find.type;
        else 
          return data;
      }

    },
    {
      title: "License type",
      dataIndex: "license_type",
      sorter: true

    },
    {
      title: "Hashcode",
      dataIndex: "hashcode",
      sorter: true

    }

  ]

  const handleChange = (pagination, filters, sorter, extra) => {
    setCurrent(pagination.current);
    setPageSize(pagination.pageSize);
    if (sorter.column) {
      if (sorter.order === 'ascend') {
        setOrdering([sorter.field])
      }
      else {
        setOrdering(['-' + sorter.field])
      }
    } else {
      setOrdering([])
    }
  }


  return <div style={{ padding: 20, height: "calc(100vh - 64px)" }}>
    <Card style={{marginBottom: 10}}>
      <Form form={form}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item label="Keyword" tooltip="Hostname, Machine Id, Hashcode" name="keyword">
              <Input allowClear/>
            </Form.Item>
          </Col>
          <Col span={12}>

            <Form.Item label="License Type" name="license_type">
              <Select options={options} allowClear/>
            </Form.Item>
          </Col>
        </Row>
        <Flex justify="flex-end">
          <Button type="primary" onClick={getData}>검색</Button>
        </Flex>
      </Form>
    </Card>
    <Card>
      <Table
        dataSource={data}
        columns={columns}
        scroll={{ y: tableScrollY }}
        onChange={handleChange}
        pagination={{
          total,
          current,
          pageSize,
        }}
      />
    </Card>
  </div>
}


export default List;