import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, message, Typography, Row } from "antd";
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import CircularProgress from "components/CircularProgress";
import { useAuth } from "util/use-auth";
import authApis from "util/auth/authApis";

import "./index.css";

const { Title } = Typography;

const SignIn = () => {
  const { isLoading, error, userLogin } = useAuth();
  // const [isReady, setIsReady] = useState(false);
  const [form] = Form.useForm();
  const [errorMsg, setErrorMsg] = useState("");

  const navigate = useNavigate();


  useEffect(() => {
    if (error) {
      setErrorMsg(error.toString());
    }
  }, [error]);

  useEffect(() => {
    const id = localStorage.getItem("rememberMe");
    if (id) {
      form.setFieldsValue({ "rememberMe": true })
      form.setFieldsValue({ "userid": id });
    }

  }, [])

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = async (values) => {
    const { userid, password, rememberMe } = values;

    if (rememberMe) {
      localStorage.setItem("rememberMe", userid);
    } else {
      localStorage.removeItem("rememberMe");
    }

    const formData = authApis.makeFormData();

    console.log("formData", formData)
    formData.append("username", userid);
    formData.append("password", password);
    // formData.append("password", btoa(password));
    console.log("formData", formData)
    for (let pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }
    try {
      userLogin(formData, () => navigate('/list'));
    } catch (e) {
      console.log("session check error", e.response.status, e.response.data);
    }
  };

  const handleChange = () => {
  //   setErrorMsg("");
  //   if (form.getFieldValue("userid")?.length && form.getFieldValue("password")?.length)
  //     setIsReady(true);
  //   else
  //     setIsReady(false);

  }

  const initialValues = {
    userId: "",
    password: "",
    rememberMe: false,
  }


  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
            </div>
            <div className="gx-app-logo" style={{ alignSelf: "center" }}>
              <img src={"/lazybird_bird.png"} style={{ width: 64, marginTop: 20, alignSelf: "center" }} />
            </div>
          </div>
          <div className="gx-app-login-content" style={{ paddingTop: 15, display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Title level={5} className="title-h5 gx-mt-0 gx-mb-4">로그인 후 시스템 이용이 가능합니다.</Title>
            <Form
              form={form}
              initialValues={initialValues}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
              style={{width: 300 }}>

              <Form.Item
                // initialValue="demo@example.com"
                // rules={[{required: true, message: ''}]} 
                name="userid">
                <Input placeholder="아이디(ID)를 입력하세요" onChange={handleChange} autoFocus />
              </Form.Item>
              <Form.Item
                // initialValue="demo#123"
                // rules={[{required: true, message: ''}]} 
                name="password">
                <Input type="password" placeholder="비밀번호를 입력하세요" onChange={handleChange} autoComplete="off" />
              </Form.Item>
              {/* <Form.Item className="button-space-between">
                {/* <Link href="">
                  <a>비밀번호재설정</a>
                </Link> */}
              {/* </Form.Item> */}
              <Form.Item name="rememberMe" valuePropName="checked" className="hy-signin-remember-me gx-mb-2">
                <Checkbox indeterminate={false}>아이디 저장</Checkbox>
              </Form.Item>
              <Row className="hy-login-error">
                <span>{errorMsg}</span>
              </Row>
              <Form.Item className="button-center">
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  로그인
                </Button>

              </Form.Item>
            </Form>
          </div>
          {/*        
          {errorMsg === "Locked" &&
            <AccountDialog
              title="로그인 연속 5회 오류"
              msg={<><div><span style={{ color: "#ee3224" }}>로그인 정보 5회 오류</span>로 로그인이 불가능합니다.</div>
                <div>시스템 관리자에게 문의해주세요.</div></>}
              handleOk={() => setErrorMsg("")}
              handleClose={() => setErrorMsg("")} />
          }

          {errorMsg === "AFK" &&
            <AccountDialog
              title="장기 미 접속 로그인​"
              msg={<><div>장기 미 사용 기한을 초과하여 로그인이</div>
                <div>불가능합니다. 시스템 관리자에게 문의해 주세요.</div></>}
              handleOk={() => setErrorMsg("")}
              handleClose={() => setErrorMsg("")} />
          }

          {errorMsg === "IP" &&
            <AccountDialog
              title="허용되지 않은 IP"
              msg={<><div>허용되지 않은 IP 입니다.</div>
                <div>시스템 관리자에게 문의해 주세요.</div></>}
              handleOk={() => setErrorMsg("")}
              handleClose={() => setErrorMsg("")} />
          }

          
          {errorMsg === "Session" &&
            <AccountDialog
              title="동시 접속 로그인"
              msg={<><div>현재 해당 계정으로 로그인 된 상태</div>
                <div>입니다. 새로 로그인 하시면 이전 로그인</div>
                <div>계정은 로그아웃됩니다.</div>
                </>}
              handleOk={() => {setErrorMsg(""); requestLogin()}}
              handleClose={() => setErrorMsg("")} />
          }


          {errorMsg === "Expired password" &&
            <ChangePassword
              userid={form.getFieldValue("userid")}
              msg1={"비밀번호 사용기간 60일을 경과하셨습니다. 관리자시스템의 원활한 이용을 위해 새로운 비밀번호로 변경해주세요."} msg2={"현재"}
              handleClose={() => {
                form.setFieldsValue({ "password": "" });
                setErrorMsg("");}} />
          }
        */}
          {isLoading &&
            <div className="gx-loader-view">
              <CircularProgress />
            </div>}
        </div>
      </div>
    </div>
  );
};

export default SignIn;

