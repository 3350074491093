import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";

const antIcon = <Loading3QuartersOutlined style={{ fontSize: 28 }} spin />;

const CircularProgress = ({className, spin}) => spin ? <Spin indicator={antIcon} /> : 
<div className={`loader ${className}`}>
  <Spin indicator={antIcon} />
</div>;
export default CircularProgress;
CircularProgress.defaultProps = {
  className: ''
}
