import React, { useEffect } from 'react';
import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import MainApp from './MainApp';
import { useAuth } from './util/use-auth';
import CircularProgress from "components/CircularProgress";
import SignIn from 'pages/SignIn';
import UserProfile from 'components/UserProfile';

const { Header, Content, Sider } = Layout;

const items1 = ['1', '2', '3'].map((key) => ({
  key,
  label: `nav ${key}`,
}));
const items2 = [UserOutlined, LaptopOutlined, NotificationOutlined].map((icon, index) => {
  const key = String(index + 1);
  return {
    key: `sub${key}`,
    icon: React.createElement(icon),
    label: `subnav ${key}`,
    // children: new Array(4).fill(null).map((_, j) => {
    //   const subKey = index * 4 + j + 1;
    //   return {
    //     key: subKey,
    //     label: `option${subKey}`,
    //   };
    // }),
  };
});

const menuItems = [
  {
    key: "list",
    label: <Link to="/list">라이센스 목록</Link>
  },
  {
    key: "create",
    label: <Link to="/create">라이센스 발급</Link>
  }
]

const MainLayout = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const location = useLocation();
  const navigate = useNavigate();
  
  const { authUser, isLoadingUser } = useAuth();
  

  const selectedKeys = location.pathname.substring(1);


  
  useEffect(() => {
    if (!isLoadingUser) {

      if (!authUser ) {
        // console.log("location.pathname", location);
        const pathname = location.pathname+location.search;
        if (pathname.length > 1 && pathname !== '/signin') {
          localStorage.setItem('initUrl', pathname)
        }
        else {
          localStorage.setItem('initUrl', '')

        }
        navigate('/signin');
      }
       else if (authUser ) {
        const initUrl = localStorage.getItem('initUrl');
        if (initUrl.length > 1) {
          localStorage.setItem('initUrl', '')
          navigate(initUrl);
        }
        else {

        }
      } 
    }
  }, [authUser, isLoadingUser])



  if (isLoadingUser) {
    return <div className="gx-loader-view">
      <CircularProgress />
    </div>;
  }

  if (!authUser) {
    return <SignIn />
  }

  return <Layout style={{height: "100%", overflow: "hidden"}}>
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <div className="demo-logo" style={{ width: 200, height: "100%", display: "flex", padding: "5px 10px 0 30px", cursor: "pointer" }} onClick={() => navigate('/list')}>
          <img width={50} height={50} style={{ paddingTop: 5 }} src={`${process.env.PUBLIC_URL}/lazybird_bird.png`}/>
          <div style={{ fontSize: 22 }}>LazyBird</div>
        </div>
        <UserProfile />
        {/* <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['2']}
          items={items1}
          style={{
            flex: 1,
            minWidth: 0,
          }}
        /> */}
      </Header>
      <Layout>
        <Sider
          width={200}
          style={{
            background: colorBgContainer,
          }}
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            selectedKeys={[selectedKeys]}

            style={{
              height: '100%',
              borderRight: 0,
              paddingTop: 5,
              boxShadow: "inset 1px 6px 4px -3px rgba(0,0,0,0.1)",
            }}
            items={menuItems}
          />
        </Sider>
        <Layout
          style={{
            // padding: '0 24px 24px',
            boxShadow: "inset 1px 6px 4px -3px rgba(0,0,0,0.1)",
            flex: "1"
          }}
        >
          <Routes>
            <Route path="/*" element={<MainApp />} />
          </Routes>

          {/* <Breadcrumb
            style={{
              margin: '16px 0',
            }}
          >
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>List</Breadcrumb.Item>
            <Breadcrumb.Item>App</Breadcrumb.Item>
          </Breadcrumb>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            Content
          </Content> */}
        </Layout>
      </Layout>
    </Layout>
};
export default MainLayout;