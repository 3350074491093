import React, { useState, useContext, createContext } from "react";
import { Collapse } from "antd";

const popupContext = createContext({});

var infoMessageCount = 0;
var warningMessageCount = 0;
var errorMessageCount = 0;
var errorCatchMessageCount = 0;


export function PopupProvider({ modal, children }) {
    const popup = useProvidePopup();
    return <popupContext.Provider value={popup}>{children}</popupContext.Provider>;
}


export const usePopup = () => {
    return useContext(popupContext);
};


const useProvidePopup = () => {
    const [modal, setModal] = useState(null);

    const confirmPopup = (content, onOk, onCancel) => {
        modal.confirm({
            title: "Confirm",
            centered: true,
            content: <div style={{fontSize: 16}}>{content}</div>,
            okText: "Ok",
            cancelText: "Cancel",
            onOk: () => {
                onOk && onOk();
            },
            onCancel: () => {
                onCancel && onCancel();
            }
        });
    }

    const infoPopup = (content, onOk) => {
        if (infoMessageCount === 1) {
            return;
        }
        infoMessageCount = 1;

        modal.info({
            title: "Info",
            centered: true,
            content: content,
            okText: "Ok",
            onOk: () => { onOk && onOk(); infoMessageCount = 0; }
        });
    }

    const warningPopup = (content, onOk) => {
        if (warningMessageCount === 1) {
            return;
        }
        warningMessageCount = 1;

        modal.warning({
            title: "Warning",
            centered: true,
            content: content,
            okText: "Ok",
            onOk: () => { onOk && onOk(); warningMessageCount = 0; }
        });
    }

    const errorPopup = (content, onOk) => {
        if (errorMessageCount === 1) {
            return;
        }
        errorMessageCount = 1;

        modal.error({
            title: "Error",
            centered: true,
            content: content,
            okText: "Ok",
            onOk: () => { onOk && onOk(); errorMessageCount = 0; }
        });
    }


    const errorCatchPopup = (content, onOk) => {
        if (errorCatchMessageCount === 1) {
            return;
        }
        errorCatchMessageCount = 1;

        modal.error({
            title: "Error",
            centered: true,
            content: content,
            okText: "Ok",
            onOk: () => { onOk && onOk(); errorCatchMessageCount = 0; }
        });
    }


    return {
        confirmPopup,
        infoPopup,
        warningPopup,
        errorPopup,
        errorCatchPopup,
        setModal,

    };
}