import React, { useEffect } from "react";
import { Layout, Modal } from "antd";
// import {useDispatch} from "react-redux";
import { Route, Routes, Navigate , useLocation, useNavigate } from "react-router-dom";

import { usePopup } from "./util/use-popup";

import List from "./pages/List";
import Create from "./pages/Create";

const MainApp = () => {
  const { setModal } = usePopup();
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    if (modal && setModal)
      setModal(modal);
  }, [modal, setModal]);



  return (
    <>
      <Routes>
        <Route path='/list' element={<List />} />
        <Route path='/create' element={<Create />} />
      </Routes>
      {contextHolder}
    </>

  )
};
export default MainApp;

