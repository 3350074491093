import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { ConfigProvider } from 'antd';
import MainLayout from './MainLayout';
import { PopupProvider } from './util/use-popup';
import { Provider } from 'react-redux';
import configureStore from './appRedux/store';
import { AuthProvider } from './util/use-auth';

const store = configureStore();

const App = () => (
  <ConfigProvider theme={{ components: { Layout: { headerBg: "#fff", headerPadding: 0 } } }}>
    <AuthProvider>
      <Provider store={store}>
        <PopupProvider>
          <Router basename="/license">
            <MainLayout />
          </Router>
        </PopupProvider>
      </Provider>
    </AuthProvider>
  </ConfigProvider>
);

export default App;