
export const PRODUCT_TYPES = {
  "calltree": "1",
  "tool2": "2",
  "tool3": "3"
}


export const LICENSE_TYPES = [
  'demo',
  'full'
]
