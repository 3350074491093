import React, { useState, useEffect, useContext, createContext } from "react";
import { httpClient } from "./Api";
import { Cookies } from "react-cookie";

import authApis from "./auth/authApis";

const authContext = createContext({});

// Provider component that wraps app and makes auth object ..
// ... available to any child component that calls useAuth().

export function AuthProvider({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.

export const useAuth = () => {
  return useContext(authContext);
};

const useProvideAuth = () => {
  const [authUser, setAuthUser] = useState(null);
  const [isLoadingUser, setLoadingUser] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');



  const fetchStart = () => {
    setLoading(true);
    setError('');
  }

  const fetchSuccess = () => {
    setLoading(false);
    setError('');
  }

  const fetchError = (error) => {
    setLoading(false);
    setError(error);
  }

  const userLogin = (data, callbackFun) => {
    // console.log("userLogin", data);
    fetchStart();
    httpClient.post('oauth/token/', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      .then((res) => {
        if (res.data) {
          fetchSuccess();

          httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access_token;
          const cookies = new Cookies();
          cookies.set('token', res.data.access_token, { path: '/' });

          //cookies.set('refresh_token', res.data.refresh_token, { path: '/' });
          //cookies.set('expires_in', res.data.expires_in, { path: '/' });
          // console.log("use-auth login set token", res.data.access_token);
          getAuthUser();
          if (callbackFun) callbackFun();

        }  else{        
          fetchError(res.data.error);
        }
      }

        /*  if (res.data.is_first) {
            setAuthUser(res.data);

          }
          else {

            httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access_token;
            const cookies = new Cookies();
            cookies.set('token', res.data.access_token, { path: '/' });
            cookies.set('refresh_token', res.data.refresh_token, { path: '/' });
            cookies.set('expires_in', res.data.expires_in, { path: '/' });
            console.log("use-auth login set token", res.data.access_token);

            getAuthUser();

            setTimeout(function(){
                refreshToken(null);
            // } , (30 * 1000));
          } , ((res.data.expires_in - 60) * 1000));

          }

          // getAuthUser();
          if (callbackFun) callbackFun();
        } else {
          fetchError(res.data.error);
        }
      })
      .catch((error) => {
        const data = error.response.data;

        if (data.error === "invalid_grant") {
          if (data.error_description === "Invalid credentials given." && data.login_fail_count > 0) {
            if ( data.login_fail_count >= 5)
              fetchError("Locked");
            else
              fetchError("로그인 정보가 없습니다. 아이디와 비밀번호를 확인 하시고 재로그인 하시기 바랍니다. 비밀번호 입력오류: " + data.login_fail_count + '회');
          }
          else
            fetchError("로그인 정보가 없습니다. 아이디와 비밀번호를 확인 하시고 재로그인 하시기 바랍니다.");
        }
        else if (data.error === "Permission denied") {
          if (data.error_description === "Locked") //5번 비번 오류
            fetchError(data.error_description);
          else if (data.error_description === "AFK") //장기간 미접속자
            fetchError(data.error_description);
          else if (data.error_description === "Expired password") //비번 변경한지 60일 지남
            fetchError(data.error_description);
          else if (data.error_description.includes("IP=")) //허용되지 않은 IP
            fetchError("IP");
          else if (data.error_description === "Disabled")
            fetchError("사용자 계정이 비활성화 상태입니다. 시스템 관리자에게 문의 바랍니다.");            
          else
            fetchError(data.error + ':' + data.error_description + ':' + data.detail)
        }

  */)
  .catch((error) => {
    console.log("error", error.response.data);
    const data = error.response.data;
      if (data.error === "invalid_grant") {
        fetchError("로그인 정보가 없습니다. 아이디와 비밀번호를 확인 하시고 재로그인 하시기 바랍니다.");
      }else{
        fetchError(data.error + ":" + data.error_description);
      }
    })
  };

  const userSignup = (data, callbackFun) => {
    // fetchStart();
    // httpClient.post('auth/register', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
    //   .then(({ data }) => {
    //     if (data.result) {
    //       fetchSuccess();
    //       httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.access_token;
    //       const cookies = new Cookies();
    //       cookies.set('token', data.token.access_token, { path: '/' });
    //       if (callbackFun) callbackFun();
    //     } else {
    //       fetchError(data.error);
    //     }
    //   })
    //   .catch(function (error) {
    //     fetchError(error.message);
    //   });
  };

  const userSignOut = (callbackFun) => {
    const cookies = new Cookies();
    const token = cookies.get("token", { path: '/' });

    const formData = authApis.makeFormData();
    formData.append("token", token);

    httpClient.post('oauth/revoke/', formData, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
    .then((res) => {
      // console.log("revoke success res:", res)

    })
    .catch((error) => {
      console.log("revoke error res:", error)
    })
        
    setError('');
    setAuthUser(false);
    httpClient.defaults.headers.common['Authorization'] = '';
    cookies.remove('token', { path: '/' });
    cookies.remove('refresh_token', { path: '/' });

  };

  const getAuthUser = () => {
    fetchStart();
    const cookies = new Cookies();
    const token = cookies.get("token", { path: '/' });
    // console.log("use-auth login set token", token);

    httpClient.get("v1/bm/profile/").then(({ data }) => {
      if (data) {
        console.log("profile data", data)
        fetchSuccess();
        setAuthUser(data.data);
      } else {
        fetchError(data.error);
      }
    }).catch(function (error) {
      httpClient.defaults.headers.common['Authorization'] = '';
      fetchError(error.message);
    });
  }

  const refreshToken = (callback) => {

    const cookies = new Cookies();
    const refresh_token = cookies.get("refresh_token", { path: '/' });

    const formData = authApis.makeFormData();
    formData.set("grant_type", "refresh_token")
    formData.append("refresh_token", refresh_token);

    // console.log("refresh token request start");

    httpClient.post('oauth/refresh/', formData, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
    .then((res) => {
      if (res.data) {      

        httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access_token;
        const cookies = new Cookies();
        cookies.set('token', res.data.access_token, { path: '/' });
        cookies.set('refresh_token', res.data.refresh_token, { path: '/' });
        // console.log("use-auth login set token", res.data.access_token);

        if (callback) callback();

        setTimeout(() => {
          refreshToken(null);
        // }, (30 * 1000))
      }, ((res.data.expires_in - 60) * 1000))
      } 
    })
    .catch((error) => {
      const data = error.response.data;
      console.log("refresh token error",error, data);

    })
    .finally(() => {
      // console.log("refresh token request end");
    })
  }

  
  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.

  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get("token", { path: '/' });
    const expires_in = cookies.get("expires_in", { path: '/' });
    console.log("useEffect : use-auth get token", token);
    if (token) {
      httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      httpClient.get("v1/bm/profile/").then(({ data }) => {
        console.log("profile data", data)
        if (data) {
          setAuthUser(data.data);
        }
        setLoadingUser(false);
      }).catch(function (error) {
        cookies.remove('token', { path: '/' });
        //cookies.remove('refresh_token', { path: '/' });
        httpClient.defaults.headers.common['Authorization'] = '';
        setLoadingUser(false);
      })
    }
    else {
      // console.log("token is null")
      setAuthUser(null);
      setLoadingUser(false);
    }
  }, []
  );

  // Return the user object and auth methods
  return {
    isLoadingUser,
    isLoading,
    authUser,
    error,
    setAuthUser,
    getAuthUser,
    userLogin,
    userSignup,
    userSignOut,
    refreshToken
  };
}

export const isUnRestrictedRoute = (pathname) => {
  return pathname === '/signin' || pathname === '/signup' || pathname === '/forgot-password' || pathname === '/reset-password';
}
