import dayjs from "dayjs"

export const convertDateFormat = (t, type) => {
  if (!t) {
    return ""
  }
  if (type === "withTime") {
    return dayjs(new Date(t)).format("YYYY.MM.DD HH:mm:ss")
  } else if (type === "toMonth") {
    return dayjs(new Date(t)).format("YYYY.MM")
  } else {
    return dayjs(new Date(t)).format("YYYY.MM.DD")
  }

}