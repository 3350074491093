import { httpClient } from "../Api";
class AuthApis {

  async vertifyPassword(requestData) {
    // console.log("requestData : ", requestData)
    try {
      const resp = await httpClient.post(`v1/verify_password/`, requestData)
      const data = resp.data.result;
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async sessionCheck(requestData) {
    // console.log("sessionCheck:", requestData);
    try {
      const resp = await httpClient.post(`v1/sessions/`, requestData, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
      const data = resp.data;
      return Promise.resolve(data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  makeFormData() {
    const data = {
      grant_type: "password",
      client_id: "nFoGwZBSDK7aZ35J2LvStZogB0wMKEFr2PnHhk11",
      client_secret: "vmSNn6dPyJ93weU6lH2B3kBK7r15Bt9RhIPiuJVqhRJmifkLumzRsodWy8RVyr8CfGYyJZxy7lbzdKPtvpj7YnPO8pU2VT8LzkzQe0u59KdG3AHCr9wKvmlCJ6eln5kQ"
    };
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });


    return formData;

  }
}

const authApis = new AuthApis();

export default authApis;