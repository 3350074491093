import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { PRODUCT_TYPES, LICENSE_TYPES } from "constants/License";

import { Input, Form, Button, Space, Radio, Select} from 'antd';

import { usePopup } from "util/use-popup";
import licenseApis from "util/service/licenseApis";

const lic_mask = new Uint8Array([
  72, 4, 17, 96, 50, 91, 8, 27, 148, 233, 73, 150, 191, 114, 64, 29,
  19, 203, 214, 136, 42, 23, 85, 100, 66, 111, 45, 255, 98, 145, 170, 37,
  52, 56, 132, 119, 31, 188, 196, 81, 215, 78, 93, 112, 137, 184, 195, 21,
  144, 252, 178, 183, 167, 33, 255, 59, 122, 109, 239, 168, 147, 104, 243, 213,
  12, 135, 191, 93, 176, 43, 86, 108, 32, 231, 99, 89, 95, 22, 176, 122,
  9, 191, 54, 116, 158, 23, 159, 88, 169, 7, 200, 195, 92, 80, 59, 105,
  17, 64, 69, 15, 39, 121, 7, 92, 34, 114, 19, 54, 35, 61, 150, 119,
  158, 108, 123, 11, 219, 101, 12, 72, 96, 81, 76, 24, 6, 117, 21, 116,
  107, 48, 3, 53, 58, 48, 90, 142, 12, 120, 9, 95, 74, 18, 128, 14,
  158, 19, 35, 123, 118, 43, 197, 81, 72, 105, 125, 150, 190, 105, 82, 234,
  87, 93, 82, 58, 118, 4, 126, 77, 119, 76, 139, 68, 50, 17, 11, 58,
  133, 19, 109, 86, 122, 57, 202, 108, 60, 8, 196, 83, 87, 198, 53, 4,
  126, 126, 132, 119, 96, 43, 189, 62, 122, 68, 69, 194, 91, 114, 157, 112,
  6, 137, 145, 124, 156, 80, 145, 16, 85, 203, 48, 136, 19, 25, 124, 145,
  204, 77, 108, 13, 152, 94, 106, 116, 146, 123, 160, 136, 116, 109, 29, 142,
  12, 127, 127, 108, 72, 178, 58, 54, 142, 60, 67, 129, 39, 70, 83, 22,
  76, 136, 129, 156, 25, 174, 75, 68, 119, 234, 51, 118, 75, 209, 93, 116,
  50, 91, 189, 15, 70, 57, 85, 100, 6, 11, 175, 53, 5, 16, 90, 105,
  86, 155, 32, 117, 51, 126, 68, 97, 12, 27, 164, 58, 34, 48, 111, 33,
  14, 72, 90, 73, 83, 16, 166, 9, 187, 185, 109, 68, 115, 65, 49, 121,
  94, 85, 166, 41, 190, 37, 159, 108, 232, 87, 109, 182, 12, 89, 107, 222,
  9, 101, 82, 116, 158, 54, 89, 188, 209, 73, 28, 35, 164, 180, 39, 85,
  17, 64, 69, 175, 39, 121, 67, 92, 234, 156, 219, 134, 210, 187, 62, 214,
  98, 145, 170, 37
]);

const Create = () => {
  const location = useLocation();
  const { infoPopup } = usePopup();
  const [form] = Form.useForm();

  const queryParams = new URLSearchParams(location.search);

  const hashcode = queryParams.get('hashcode');
  const toolType = queryParams.get('type');
  const hostname = queryParams.get('hostname');
  const machineId = queryParams.get('machineId');

  useEffect(() => {
    form.setFieldsValue({
      hashcode, toolType, hostname, machineId, licenseType: 'demo'
    })
  }, [location])

  const handleClear = () => {
    form.resetFields();
  }
  const padRight = (str, length) => {
    return str.padEnd(length, '\0'); // null byte (0x00)로 패딩
  };

  const onFinish = (values) => {
    const { hashcode, toolType, hostname, machineId, licenseType } = values;

    // 오늘 날짜를 YYYYMMDD 형식으로 가져오기
    const today = new Date();
    const issueDate = today.toISOString().slice(0, 10).replace(/-/g, ''); // YYYYMMDD 형식으로 변환

    let expireDate;
    if (licenseType === 'demo') {
      // 3개월 후 계산
      const expire = new Date(today);
      expire.setMonth(expire.getMonth() + 3);
      expireDate = expire.toISOString().slice(0, 10).replace(/-/g, ''); // YYYYMMDD 형식으로 변환
    } else {
      expireDate = '99991231'; // 'full' 타입의 만료일
    }

    // 발급일과 만료일을 HEX 값으로 변환
    const issueDateHex = parseInt(issueDate, 10).toString(16).padStart(8, '0');
    const expireDateHex = parseInt(expireDate, 10).toString(16).padStart(8, '0');

    // 체크섬 (4바이트) - 라이센스 파일 전체 길이 (372바이트)를 HEX로 변환
    const checkSumHex = (372).toString(16).padStart(8, '0');
    const versionInfo = '20240901';
    const versionInfoHex = parseInt(versionInfo, 10).toString(16).padStart(8, '0');

    // 라이센스 데이터 구성
    const licenseData = new Uint8Array([
      ...new Uint8Array([
        parseInt(checkSumHex.slice(0, 2), 16),
        parseInt(checkSumHex.slice(2, 4), 16),
        parseInt(checkSumHex.slice(4, 6), 16),
        parseInt(checkSumHex.slice(6, 8), 16),
      ]),
      // 버전 정보 (4바이트)
      ...new Uint8Array([
        parseInt(versionInfoHex.slice(0, 2), 16),
        parseInt(versionInfoHex.slice(2, 4), 16),
        parseInt(versionInfoHex.slice(4, 6), 16),
        parseInt(versionInfoHex.slice(6, 8), 16),
      ]),
      ...new Uint8Array(toolType.padEnd(4, ' ').split('').map(c => c.charCodeAt(0))), //툴타입
      ...new Uint8Array(hashcode.split('').map(c => c.charCodeAt(0))), // hashcode
      // 발급일 (4바이트)
      ...new Uint8Array([
        parseInt(issueDateHex.slice(0, 2), 16),
        parseInt(issueDateHex.slice(2, 4), 16),
        parseInt(issueDateHex.slice(4, 6), 16),
        parseInt(issueDateHex.slice(6, 8), 16),
      ]),

      // 만료일 (4바이트)
      ...new Uint8Array([
        parseInt(expireDateHex.slice(0, 2), 16),
        parseInt(expireDateHex.slice(2, 4), 16),
        parseInt(expireDateHex.slice(4, 6), 16),
        parseInt(expireDateHex.slice(6, 8), 16),
      ]),
      ...new Uint8Array(padRight(machineId, 32).split('').map(c => c.charCodeAt(0))), // machine_id
      ...new Uint8Array(padRight(hostname, 256).split('').map(c => c.charCodeAt(0))) // hostname
    ]);

    // 마스킹
    const maskedLicenseData = licenseData.map((byte, index) => byte ^ lic_mask[index]);




    licenseApis.issueLicense({
      hashcode,
      product_type: toolType,
      hostname,
      machine_id: machineId,
      license_type: licenseType
    }).then((resp) => {

      // 파일 다운로드
      const blob = new Blob([maskedLicenseData], { type: "application/octet-stream" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "license.dat";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      infoPopup("라이센스가 발급되었습니다.", () => {})

    })
  };


  return (
    <Form
      name="basic"
      form={form}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      style={{
        maxWidth: 1000,
        padding: 50,
      }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label="Tool Type"
        name="toolType"
        // style={{display: "none"}}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select options={Object.entries(PRODUCT_TYPES).map(([key, value]) => ({label: key, value}))}/>
      </Form.Item>

      <Form.Item
        label="Hostname"
        name="hostname"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Machine ID"
        name="machineId"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Hashcode"
        name="hashcode"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="License Type"
        name="licenseType"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group options={['demo', 'full'].map((item) => ({ label: item, value: item }))} />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Space>

          <Button onClick={handleClear}>
            Clear
          </Button>

          <Button type="primary" htmlType="submit">
            Submit
          </Button>

        </Space>

      </Form.Item>
    </Form>
  );
}


export default Create;