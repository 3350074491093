import React from "react";
import { Avatar, Button, Flex } from "antd";
import { useAuth } from "util/use-auth";
import { Link } from "react-router-dom";
import { convertDateFormat } from "util/common";

const UserProfile = () => {
  const { authUser, userSignOut } = useAuth();

  if (!authUser) return <></>;

  return (
    <Flex style={{paddingRight: 20}}>
      {/* // <Popover placement="bottomRight" content={userMenuOptions} trigger="click"> */}
      <div className="gx-pr-2" style={{ height: "36px", lineHeight: "36px", marginRight: 10 }}>
        {/* <Link href="/ap/mypage"> */}
        <span className="gx-mr-2 gx-pointer">{`${authUser.name}님(${authUser.email})`}</span>
        {/* </Link> */}
        {/* <span className="gx-mr-2">{`최종로그인  ${convertDateFormat(authUser.last_login, "withTime")}`}</span>
        <span className="gx-mr-2">{`최종 IP  ${authUser.last_login_ip}`}</span> */}
      </div>
      <Button className="gx-mb-0" type="primary" onClick={() => userSignOut()}>로그아웃</Button>
      {/* </Popover> */}
    </Flex>
  )
};

export default UserProfile;