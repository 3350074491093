import {applyMiddleware, createStore} from 'redux'
import { composeWithDevTools } from "redux-devtools-extension";
// import {routerMiddleware} from 'connected-react-router'
import {thunk} from 'redux-thunk';
import createRootReducer from '../reducers'

// const createBrowserHistory = require('history').createBrowserHistory;
// export const history = createBrowserHistory({ basename: `${process.env.PUBLIC_URL}/frontend`});
// const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk];

export default function configureStore(preloadedState = {}) {
  return createStore(
    createRootReducer(), // root reducer with router state
    preloadedState,
    composeWithDevTools(
      applyMiddleware(
        // routerMiddleware(history), // for dispatching history actions
        ...middlewares
      ),
    ),
  );
}
