import { httpClient } from "../Api";

class LicenseApis {

  async searchLicenseList(req, pageSize, pageNumber) {
    try {
      let url = '/v1/pm/license-issued-histories/?';
      if (pageSize) {
        url += `page_size=${pageSize}`
      }
      if (pageNumber) {
        url += `&page=${pageNumber}`
      }
      const resp = await httpClient.post(url, req);
      const data = resp.data;
      return Promise.resolve(data);
    } catch (e) {
      return Promise.reject(e);
    }
  }
  async issueLicense(req) {
    try {
      const resp = await httpClient.post(`/v1/pm/license-issued/`, req);
      const data = resp.data;
      return Promise.resolve(data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

}

const licenseApis = new LicenseApis();

export default licenseApis;
